export default class Facture {
    constructor(data) {
        this.facture = data.facture
        this.create_at = data.create_at
        this.bareme_mpr=data.bareme_mpr
    }
    update(data) {
        this.facture = data.facture
        this.create_at = data.create_at
        this.bareme_mpr=data.bareme_mpr
    }

    static create(data) {
        return new Facture(data);
    }
}
