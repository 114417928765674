export default class FileType {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.file = data.file;
    this.statut = data.statut;
  }
  update(data) {
    this.id = data.id;
    this.name = data.name;
    this.file = data.file;
    this.statut = data.statut;
  }

  static create(data) {
    return new FileType(data);
  }
}
