import domain from "@/environment";
import clientType from "@/models/clientType";
import axios from "axios";
const state = {
  clientType: null,
  clientTypes: null,
  clientTypeLoading: false,
};
const getters = {
  getclientType: (state) => state.clientType,
  getAllclientType: (state) => state.clientTypes,
  getLoadingclientType: (state) => state.clientTypeLoading,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGCLIENTTYPE(state, payload) {
    if (payload) {
      state.clientTypeLoading = payload;
    } else {
      state.clientTypeLoading = false;
    }
  },
  SET_CLIENTTYPE(state, payload) {
    if (payload) {
      state.clientType = clientType.create(payload);
    } else {
      state.clientType = null;
    }
  },
  PUSH_CLIENTTYPE(state, payload) {
    state.clientTypes.push(clientType.create(payload));
  },
  UPDATE_CLIENTTYPE(state, payload) {
    for (let index = 0; index < state.clientTypes.length; index++) {
      if (state.clientTypes[index].id == payload.id) {
        state.clientTypes[index].update(payload);
        break;
      }
    }
  },

  DELETE_CLIENTTYPE(state, payload) {
    for (let index = 0; index < state.clientTypes.length; index++) {
      if (state.clientTypes[index].id == payload.id) {
        state.clientTypes.splice(index, 1);
        break;
      }
    }
  },
  SET_CLIENTTYPES(state, payload) {
    if (payload) {
      state.clientTypes = payload.map((item) => clientType.create(item));
    } else {
      state.clientTypes = null;
    }
  },
};

const actions = {
  async allclientType({ commit }) {
    commit("DO_NOTHING");
    try {
      const responce = await axios.get(domain + `/typeClients`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("SET_CLIENTTYPES", responce.data.data);
      return responce.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async clientType({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const responce = await axios.get(domain + `/typeClients/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_CLIENTTYPE", responce.data.data);
      return responce.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async store_clientType({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_LOADINGCLIENTTYPE", true);
    try {
      const responce = await axios.post(domain + `/typeClients`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("PUSH_CLIENTTYPE", responce.data.data);
      commit("SET_LOADINGCLIENTTYPE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGCLIENTTYPE", false);
      return error.response.data.message;
    }
  },
  async edit_clientType({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_LOADINGCLIENTTYPE", true);
    try {
      const responce = await axios.put(
        domain + `/typeClients/` + payload.id,
        { lib: payload.lib, lang: payload.lang },
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_CLIENTTYPE", responce.data.data);
      commit("SET_LOADINGCLIENTTYPE", false);
      return responce.data.data;
    } catch (error) {
      commit("SET_LOADINGCLIENTTYPE", false);
      return error.response.data.message;
    }
  },
  async delete_clientType({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_LOADINGCLIENTTYPE", true);
    try {
      await axios.delete(domain + `/typeClients/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_CLIENTTYPE", payload);
      commit("SET_LOADINGCLIENTTYPE", false);
      return true
    } catch (error) {
      commit("SET_LOADINGCLIENTTYPE", false);
      return error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
