import Vue from "vue";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// b-modal
import { BModal, VBModal } from "bootstrap-vue";
Vue.component("b-modal", BModal);
Vue.directive("b-modal", VBModal);
