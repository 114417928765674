import domain from "@/environment";
import Piece from "../../models/piece";
import axios from "axios";

const state = {
  piece: null,
  pieces: null,
  pieceLoading: false,
  pieceRows: 0,
};
const getters = {
  getpiece: (state) => state.piece,
  getAllpiece: (state) => state.pieces,
  getLoadingpiece: (state) => state.pieceLoading,
  getTotalRowPiece: (state) => state.pieceRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGpiece(state, payload) {
    if (payload) {
      state.pieceLoading = payload;
    } else {
      state.pieceLoading = false;
    }
  },
  SET_PIECE(state, payload) {
    if (payload) {
      state.piece = Piece.create(payload);
    } else {
      state.piece = null;
    }
  },
  PUSH_PIECE(state, payload) {
    state.pieces.push(Piece.create(payload));
  },
  UPDATE_PIECE(state, payload) {
    for (let index = 0; index < state.pieces.length; index++) {
      if (state.pieces[index].id == payload.id) {
        state.pieces[index].update(payload);
        break;
      }
    }
  },

  DELETE_PIECE(state, payload) {
    for (let index = 0; index < state.pieces.length; index++) {
      if (state.pieces[index].id == payload.id) {
        state.pieces.splice(index, 1);
        break;
      }
    }
  },
  SET_PIECES(state, payload) {
    if (payload) {
      state.pieces = payload.map((item) => Piece.create(item));
    } else {
      state.pieces = null;
    }
  },
  UPDATE_PIECE_ROW(state, payload) {
    if (payload) {
      state.pieceRows = payload;
    } else {
      state.pieceRows = 0;
    }
  },
};

const actions = {
  async allpiece({ commit }, payload) {
    commit("SET_PIECES");
    commit("SET_LOADINGpiece", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      favoris: payload.favoris,
      entreprise: payload.entreprise,
      default: payload.default,
      with_default: payload.with_default,
    };
    try {
      const responce = await axios.get(domain + "/TypePieces", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_PIECES", responce.data.data.data);
      commit("UPDATE_PIECE_ROW", responce.data.data.total);
      commit("SET_LOADINGpiece", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGpiece", false);
      throw error.response.data.message;
    }
  },
  async piece({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const responce = await axios.get(domain + `/TypePieces/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_PIECE", responce.data.data);
      return responce.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async store_piece({ commit }, payload) {
    commit("SET_LOADINGpiece", true);
    try {
      const responce = await axios.post(domain + `/TypePieces`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("PUSH_PIECE", responce.data.data);
      commit("UPDATE_PIECE_ROW", state.pieceRows + 1);
      commit("SET_LOADINGpiece", false);
      return Piece.create(responce.data.data);
    } catch (error) {
      commit("SET_LOADINGpiece", false);
      throw error.response.data.message;
    }
  },
  async edit_piece({ commit }, payload) {
    commit("SET_LOADINGpiece", true);
    try {
      const responce = await axios.put(
        domain + `/TypePieces/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_PIECE", responce.data.data);
      commit("SET_LOADINGpiece", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGpiece", false);
      throw error.response.data.message;
    }
  },
  async delete_piece({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_LOADINGpiece", true);
    try {
      await axios.delete(domain + `/TypePieces/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_PIECE", payload);
      commit("UPDATE_PIECE_ROW", state.pieceRows - 1);
      commit("SET_LOADINGpiece", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGpiece", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
