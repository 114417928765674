export default class Country {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.iso_code = data.iso_code;
    this.calling_code = data.calling_code;
    this.currency = data.currency;
    this.flag = data.flag;
    this.timezone = data.timezone;
    this.translation = data.translation;
  }
  update(data) {
    this.id = data.id;
    this.name = data.name;
    this.iso_code = data.iso_code;
    this.calling_code = data.calling_code;
    this.currency = data.currency;
    this.flag = data.flag;
    this.timezone = data.timezone;
    this.translation = data.translation;
  }
  static create(data) {
    return new Country(data);
  }
}
