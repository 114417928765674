import domain from "@/environment";
import categorieMetier from "../../models/categorieMetier";
import axios from "axios";

const state = {
  categorieMetier: null,
  categorieMetiers: [],
  categorieMetierLoading: false,
  categorieMetierRows: 0,
};
const getters = {
  getcategorieMetier: (state) => state.categorieMetier,
  getAllcategorieMetier: (state) => state.categorieMetiers,
  getLoadingcategorieMetier: (state) => state.categorieMetierLoading,
  getTotalRowcategorieMetier: (state) => state.categorieMetierRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGCATEGORIEMETIER(state, payload) {
    if (payload) {
      state.categorieMetierLoading = payload;
    } else {
      state.categorieMetierLoading = false;
    }
  },
  SET_CATEGORIEMETIER(state, payload) {
    if (payload) {
      state.categorieMetier = categorieMetier.create(payload);
    } else {
      state.categorieMetier = null;
    }
  },
  PUSH_CATEGORIEMETIER(state, payload) {
    state.categorieMetiers.push(categorieMetier.create(payload));
  },
  UPDATE_CATEGORIEMETIER(state, payload) {
    for (let index = 0; index < state.categorieMetiers.length; index++) {
      if (state.categorieMetiers[index].id == payload.id) {
        state.categorieMetiers[index].update(payload);
        break;
      }
    }
  },

  DELETE_CATEGORIEMETIER(state, payload) {
    for (let index = 0; index < state.categorieMetiers.length; index++) {
      if (state.categorieMetiers[index].id == payload.id) {
        state.categorieMetiers.splice(index, 1);
        break;
      }
    }
  },
  SET_CATEGORIEMETIERS(state, payload) {
    if (payload) {
      state.categorieMetiers = payload.map((item) =>
        categorieMetier.create(item)
      );
    } else {
      state.categorieMetiers = [];
    }
  },
  UPDATE_CATEGORIEMETIER_ROW(state, payload) {
    if (payload) {
      state.categorieMetierRows = payload;
    } else {
      state.categorieMetierRows = 0;
    }
  },
};

const actions = {
  async allcategorieMetier({ commit }, payload) {
    commit("SET_LOADINGCATEGORIEMETIER", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
    };
    try {
      const response = await axios.get(domain + "/categorie-metier", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("UPDATE_CATEGORIEMETIER_ROW", response.data.data.total);
      commit("SET_CATEGORIEMETIERS", response.data.data.data);
      commit("SET_LOADINGCATEGORIEMETIER", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGCATEGORIEMETIER", false);
      return false;
    }
  },
  async categorieMetier({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const response = await axios.get(
        domain + `/categorie-metier/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_CATEGORIEMETIER", response.data.data);
      return true;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async store_categorieMetier({ commit }, payload) {
    commit("SET_LOADINGCATEGORIEMETIER", true);
    const params = {
      lib: payload.lib,
      entreprise_id: payload.entreprise_id,
      lang: "fr",
    };
    try {
      const response = await axios.post(domain + `/categorie-metier`, null, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("PUSH_CATEGORIEMETIER", response.data.data);
      commit("UPDATE_CATEGORIEMETIER_ROW", state.categorieMetierRows + 1);
      commit("SET_LOADINGCATEGORIEMETIER", false);
      return categorieMetier.create(response.data.data);
    } catch (error) {
      commit("SET_LOADINGCATEGORIEMETIER", false);
      throw error.response.data.message;
    }
  },
  async edit_categorieMetier({ commit }, payload) {
    commit("SET_LOADINGCATEGORIEMETIER", true);
    try {
      const response = await axios.put(
        domain + `/categorie-metier/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_CATEGORIEMETIER", response.data.data);
      commit("SET_LOADINGCATEGORIEMETIER", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADINGCATEGORIEMETIER", false);
      throw error.response.data.message;
    }
  },
  async delete_categorieMetier({ commit }, payload) {
    commit("SET_LOADINGCATEGORIEMETIER", true);
    try {
      await axios.delete(domain + `/categorie-metier/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_CATEGORIEMETIER", payload);
      commit("UPDATE_CATEGORIEMETIER_ROW", state.categorieMetierRows - 1);
      commit("SET_LOADINGCATEGORIEMETIER", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGCATEGORIEMETIER", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
