export default class ClientType {
  constructor(data) {
    this.id = data.id;
    this.lib = data.lib;
  }
  update(data) {
    this.id = data.id;
    this.lib = data.lib;
  }

  static create(data) {
    return new ClientType(data);
  }
}
