import axios from "axios";
import domain from "@/environment";
import  Delegataire from "../../models/delegataire"
const state = {
  Delegataires: [],
  DelegataireLoading: false,
  DelegataireRows: null,
};
const getters = {
  getDelegataireLoading: (state) => state.DelegataireLoading,
  gettotalDelegataireRows: (state) => state.DelegataireRows,
  getAllDelegataire: (state) => state.Delegataires,
};
const mutations = {
  SET_LOADINGDELEGATAIRES(state, payload) {
    if (payload) {
      state.DelegataireLoading = payload;
    } else {
      state.DelegataireLoading = false;
    }
  },
  ADD_DELEGATAIRES(state, payload) {
    state.Delegataires.push(Delegataire.create(payload));
  },
  UPDATE_DELEGATAIRES(state, payload) {
    for (let index = 0; index < state.Delegataires.length; index++) {
      if (state.Delegataires[index].id == payload.id) {
        state.Delegataires[index] = payload;
        break;
      }
    }
  },
  DELETE_DELEGATAIRES(state, payload) {
    for (let index = 0; index < state.Delegataires.length; index++) {
      if (state.Delegataires[index].id == payload.id) {
        state.Delegataires.splice(index, 1);
        break;
      }
    }
  },
  UPDATE_DELEGATAIRES_ROW(state, payload) {
    if (payload) {
      state.DelegataireRows = payload
    } else {
      state.DelegataireRows = 0;
    }
  },
  SET_DELEGATAIRES(state, payload) {
    if (payload) {
      state.Delegataires = payload.map((item) => Delegataire.create(item));
    } else {
      state.Delegataires = [];
    }
  },
};
const actions = {

  async allDelegataire({ commit }, payload) {
    commit("SET_LOADINGDELEGATAIRES", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      entreprise_id: payload.entreprise_id,
    };
    try {
      const responce = await axios.get(
        domain +
        "/delegataire",
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
          params
        }
      );

      commit("SET_DELEGATAIRES", responce.data.data.data);
      commit("UPDATE_DELEGATAIRES_ROW", responce.data.data.total);
      commit("SET_LOADINGDELEGATAIRES", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGDELEGATAIRES", false);
      throw error.response.data.message
    }
  },
  async store_Delegataires({ commit }, payload) {
    commit("SET_LOADINGDELEGATAIRES", true);
    try {
      const responce = await axios.post(domain + `/delegataire`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("ADD_DELEGATAIRES", responce.data.data);
      commit("SET_LOADINGDELEGATAIRES", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGDELEGATAIRES", false);
      throw error.response.data.message
    }
  },
  async delete_Delegataires({ commit }, payload) {
    commit("SET_LOADINGDELEGATAIRES", true);
    try {
      await axios.delete(domain + `/delegataire/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      })
      commit("DELETE_DELEGATAIRES", payload);
      commit("SET_LOADINGDELEGATAIRES", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGDELEGATAIRES", false);
      throw error.response.data.message
    }
  },
  async edit_Delegataires({ commit }, payload) {
    commit("SET_LOADINGDELEGATAIRES", true);
    try {
      const responce = await axios.put(
        domain + `/delegataire/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("UPDATE_DELEGATAIRES", responce.data.data);
      commit("SET_LOADINGDELEGATAIRES", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGDELEGATAIRES", false);
      throw error.response.data.message
    }
  },

};

export default { state, mutations, getters, actions };
