import domain from "@/environment";
import Dictionnaire from "../../models/dictionnaire";
import axios from "axios";
import i18n from "../../i18n";
import index from "../index";

const state = {
  dictionnaire: null,
  dictionnaires: [],
  dictionnaireLoading: false,
  traductionLoading: false,
  dictionnaireRows: null,
};
const getters = {
  getdictionnaire: (state) => state.dictionnaire,
  getAllDictionnaire: (state) => state.dictionnaires,
  getLoadingdictionnaire: (state) => state.dictionnaireLoading,
  getLoadingtraduction: (state) => state.traductionLoading,
  getTotalDictionnaire: (state) => state.dictionnaireRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGDICTIONNAIRE(state, payload) {
    if (payload) {
      state.dictionnaireLoading = payload;
    } else {
      state.dictionnaireLoading = false;
    }
  },
  SET_LOADINGTRAD(state, payload) {
    if (payload) {
      state.traductionLoading = payload;
    } else {
      state.traductionLoading = false;
    }
  },

  SET_DICTIONNAIRE(state, payload) {
    if (payload) {
      state.dictionnaire = payload.map((item) => Dictionnaire.create(item));
      state.dictionnaire.forEach((mot) => {
        mot.lib = mot.lib[index.state.selectedLang.iso_code];
      });
      const messages = state.dictionnaire.map((d) => {
        return d.format();
      });
      var json = {};
      messages.forEach((element) => {
        var key = element.key;
        var value = element.value;
        json[key] = value;
      });
      i18n.setLocaleMessage(i18n.locale, json);
    } else {
      state.dictionnaire = null;
    }
  },
  PUSH_DICTIONNAIRE(state, payload) {
    state.dictionnaires.push(Dictionnaire.create(payload));
  },
  UPDATE_DICTIONNAIRE(state, payload) {
    for (let index = 0; index < state.dictionnaires.length; index++) {
      if (state.dictionnaires[index].id == payload.id) {
        state.dictionnaires[index].update(payload);
        break;
      }
    }
  },

  DELETE_DICTIONNAIRE(state, payload) {
    for (let index = 0; index < state.dictionnaires.length; index++) {
      if (state.dictionnaires[index].id == payload.id) {
        state.dictionnaires.splice(index, 1);
        break;
      }
    }
  },
  SET_DICTIONNAIRES(state, payload) {
    if (payload) {
      state.dictionnaires = payload.map((item) => Dictionnaire.create(item));
    } else {
      state.dictionnaires = null;
    }
  },
  UPDATE_DICTIONNAIRE_ROW(state, payload) {
    if (payload) {
      state.dictionnaireRows = payload;
    } else {
      state.dictionnaireRows = null;
    }
  },
};

const actions = {
  async allDictionnaire({ commit }, payload) {
    commit("SET_LOADINGDICTIONNAIRE", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      lang: payload.lang,
      search: payload.search,
    };
    try {
      const responce = await axios.get(domain + `/Dictionnaire`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_DICTIONNAIRES", responce.data.data.data);
      commit("UPDATE_DICTIONNAIRE_ROW", responce.data.data.total);
      commit("SET_LOADINGDICTIONNAIRE", false);

      return responce.data.data;
    } catch (error) {
      commit("SET_LOADINGDICTIONNAIRE", false);
      return error.response.data.message;
    }
  },
  async dictionnaire({ commit }, payload) {
    commit("SET_LOADINGTRAD", true);
    const params = {
      lang: payload.iso_code,
    };
    try {
      const responce = await axios.get(domain + `/all_Dictionnaire`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_DICTIONNAIRE", responce.data.data);
      commit("SET_LOADINGTRAD", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGTRAD", false);
      return error.response.data.message;
    }
  },
  async store_dictionnaire({ commit }, payload) {
    commit("SET_LOADINGDICTIONNAIRE", true);
    const params = {
      lib: payload.lib,
      key: payload.key,
      lang: payload.lang.iso_code,
    };
    try {
      const responce = await axios.post(domain + `/Dictionnaire`, params, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("PUSH_DICTIONNAIRE", responce.data.data);
      commit("SET_LOADINGDICTIONNAIRE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGDICTIONNAIRE", false);
      return error.response.data.message;
    }
  },
  async edit_dictionnaire({ commit }, payload) {
    commit("SET_LOADINGDICTIONNAIRE", true);
    try {
      const responce = await axios.put(
        domain + `/Dictionnaire/${payload.id}`,
        { lib: payload.lib, lang: payload.lang },
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_DICTIONNAIRE", responce.data.data);
      commit("SET_LOADINGDICTIONNAIRE", false);
      return responce.data.data;
    } catch (error) {
      commit("SET_LOADINGDICTIONNAIRE", false);
      return error.response.data.message;
    }
  },
  async delete_dictionnaire({ commit }, payload) {
    commit("SET_LOADINGDICTIONNAIRE", true);
    try {
      await axios.delete(domain + `/Dictionnaire/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_DICTIONNAIRE", payload);
      commit("SET_LOADINGDICTIONNAIRE", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGDICTIONNAIRE", false);
      return error.response.data.message;
    }
  },
  async import_dictionnaire({ commit }, payload) {
    commit("SET_LOADINGDICTIONNAIRE", true);
    try {
      const responce = await axios.post(
        domain + `/dictionnaire/import?lang=` + payload.lang,
        payload.file,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      commit("SET_LOADINGDICTIONNAIRE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGDICTIONNAIRE", false);
      return error.response.data.message;
    }
  },
  async translation({ commit }) {
    commit("SET_LOADINGTRAD", true);
    try {
      const responce = await axios.get(domain + `/translation`);
      commit("SET_DICTIONNAIRE", responce.data.data);
      commit("SET_LOADINGTRAD", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGTRAD", false);
      return error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
