export default class Delegataire {
  constructor(data) {
    this.id = data.id;
    this.nom = data.nom;
    this.siren = data.siren;
    this.paragraphCEE = data.paragraphCEE;
    this.paragraphMPR = data.paragraphMPR;
    this.entreprise = data.entreprise;
  }
  update(data) {
    this.id = data.id;
    this.nom = data.nom;
    this.siren = data.siren;
    this.paragraphCEE = data.paragraphCEE;
    this.paragraphMPR = data.paragraphMPR;
    this.entreprise = data.entreprise;
  }

  static create(data) {
    return new Delegataire(data);
  }
}
