import domain from "@/environment";
import Avoir from "../../models/avoir";
import axios from "axios";

const state = {
  avoir: null,
  avoirs: [],
  avoirLoading: false,
  avoirRows: 0,
  error: null,
};
const getters = {
  getAvoir: (state) => state.avoir,
  getAllavoirs: (state) => state.avoirs,
  getAvoirLoading: (state) => state.avoirLoading,
  getTotalRowavoir: (state) => state.avoirRows,
  getAvoirError: (state) => state.error,
};
const mutations = {
  DO_NOTHING() {},
  SET_ERROR(state, payload) {
    if (payload) {
      state.error = payload;
    } else {
      state.error = null;
    }
  },
  SET_AVOIR(state, payload) {
    if (payload) {
      state.avoir = Avoir.create(payload);
    } else {
      state.avoir = null;
    }
  },

  SET_AVOIRS(state, payload) {
    if (payload) {
      state.avoirs = payload.map((item) => Avoir.create(item));
    } else {
      state.avoirs = null;
    }
  },
  SET_AVOIRLOADING(state, payload) {
    if (payload) {
      state.avoirLoading = payload;
    } else {
      state.avoirLoading = false;
    }
  },
  PUSH_AVOIR(state, payload) {
    state.avoirs.push(Avoir.create(payload));
  },
  UPDATE_AVOIR_ROW(state, payload) {
    if (payload) {
      state.avoirRows = payload;
    } else {
      state.avoirRows = 0;
    }
  },
};

const actions = {
  async all_avoirs({ commit }, payload) {
    commit("SET_AVOIRLOADING", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      entreprise_id: payload.entreprise,
    };
    try {
      const response = await axios.get(domain + `/factureAvoir`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_AVOIRS", response.data.data.data);
      commit("UPDATE_AVOIR_ROW", response.data.data.total);
      commit("SET_AVOIRLOADING", false);
      return true;
    } catch (error) {
      commit("SET_AVOIRLOADING", false);
      return error;
    }
  },
  async avoir({ commit }, payload) {
    commit("SET_AVOIRLOADING", true);
    try {
      const response = await axios.get(domain + `/factureAvoir/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_AVOIR", response.data.data);
      commit("SET_AVOIRLOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_AVOIRLOADING", false);
      return error.response.data.message;
    }
  },

  async store_avoir({ commit }, payload) {
    commit("SET_AVOIRLOADING", true);
    try {
      const response = await axios.post(domain + `/factureAvoir`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        payload,
      });
      commit("PUSH_AVOIR", response.data.data);
      commit("UPDATE_AVOIR_ROW", state.avoirRows + 1);
      commit("SET_AVOIRLOADING", false);
      return true;
    } catch (error) {
      commit("SET_AVOIRLOADING", false);
      commit("SET_ERROR", error.response.data.message);
      throw error.response.data.message;
    }
  },

  async download_avoir({ commit }, payload) {
    commit("SET_AVOIRLOADING", true);
    try {
      const responce = await axios.get(
        domain + `/factureAvoir/downloadPdf/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_AVOIRLOADING", false);
      return responce.data.data.pdf;
    } catch (error) {
      commit("SET_AVOIRLOADING", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
