import Entreprise from "./entreprise";
export default class Forfait {
  constructor(data) {
    this.id = data.id;
    this.favoris = data.favoris == 1 ? true : false;
    this.lib = data.lib;
    this.ref = data.ref;
    this.tva = data.tva;
    this.desc = data.desc;
    this.type_unite = data.type_unite;
    this.quantite = data.quantite;
    this.unit_price = data.unit_price;
    if (data.entreprise) this.entreprise = Entreprise.create(data.entreprise);
    else this.entreprise = { lib: "Toutes les entreprises" };
    this.is_default = data.is_default;
  }
  update(data) {
    this.favoris = data.favoris == 1 ? true : false;
    this.lib = data.lib;
    this.ref = data.ref;
    this.tva = data.tva;
    this.desc = data.desc;
    this.type_unite = data.type_unite;
    this.quantite = data.quantite;
    this.unit_price = data.unit_price;
    if (data.entreprise) this.entreprise = Entreprise.create(data.entreprise);
    else this.entreprise = { lib: "Toutes les entreprises" };
    this.is_default = data.is_default;
  }

  static create(data) {
    return new Forfait(data);
  }
}
