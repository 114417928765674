import domain from "@/environment";
import Dossier from "../../models/dossier";
import axios from "axios";
import FileType from "../../models/ocrfileTypes";
import File from "../../models/ocrFile";

const state = {
  dossier: null,
  dossiers: [],
  delegataires: [],
  fileTypes: [],
  files: [],
  dossierLoading: false,
  dossierRows: 0,
};
const getters = {
  getdossier: (state) => state.dossier,
  getAlldossiers: (state) => state.dossiers,
  getOcrDelegataires: (state) => state.delegataires,
  getOcrFileType: (state) => state.fileTypes,
  getOcrFiles: (state) => state.files,
  getLoadingdossier: (state) => state.dossierLoading,
  getTotalRowDossier: (state) => state.dossierRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_DOSSIER(state, payload) {
    if (payload) {
      state.dossier = Dossier.create(payload);
    } else {
      state.dossier = null;
    }
  },
  SET_OCRDELEGATAIRE(state, payload) {
    if (payload) {
      state.delegataires = payload;
    } else {
      state.delegataires = [];
    }
  },
  SET_OCR_FILE_TYPE(state, payload) {
    if (payload) {
      state.fileTypes = payload.map((item) => FileType.create(item));
    } else {
      state.fileTypes = [];
    }
  },
  SET_OCR_FILES(state, payload) {
    if (payload) {
      state.files = payload.map((item) => File.create(item));
    } else {
      state.files = [];
    }
  },
  PUSH_DOSSIER(state, payload) {
    state.dossiers.push(Dossier.create(payload));
  },
  UPDATE_DOSSIER(state, payload) {
    for (let index = 0; index < state.dossiers.length; index++) {
      if (state.dossiers[index].id == payload.id) {
        state.dossiers[index].update(payload);
        break;
      }
    }
  },

  DELETE_DOSSIER(state, payload) {
    for (let index = 0; index < state.dossiers.length; index++) {
      if (state.dossiers[index].id == payload.id) {
        state.dossiers.splice(index, 1);
        break;
      }
    }
  },
  SET_DOSSIERS(state, payload) {
    if (payload) {
      state.dossiers = payload.map((item) => Dossier.create(item));
    } else {
      state.dossiers = [];
    }
  },
  UPDATE_DOSSIER_ROW(state, payload) {
    if (payload) {
      state.dossierRows = payload;
    } else {
      state.dossierRows = 0;
    }
  },

  SET_DOSSIERLOADING(state, payload) {
    if (payload) {
      state.dossierLoading = payload;
    } else {
      state.dossierLoading = false;
    }
  },
};

const actions = {
  async OcrDelegataires({ commit }, payload) {
    var params = {
      search: payload.search,
    };
    try {
      const response = await axios.get(domain + "/ocrdelegataires", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_OCRDELEGATAIRE", response.data.data);
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async OcrFileTypes({ commit }, payload) {
    var params = {
      search: payload.search,
    };
    try {
      const response = await axios.get(domain + "/ocrfiletypes", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_OCR_FILE_TYPE", response.data.data);
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async alldossier({ commit }, payload) {
    commit("SET_DOSSIERS");
    commit("SET_DOSSIERLOADING", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      client: payload.client,
    };
    try {
      const response = await axios.get(domain + "/ocrdossiers", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("UPDATE_DOSSIER_ROW", response.data.data.length);
      commit("SET_DOSSIERS", response.data.data);
      commit("SET_DOSSIERLOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_DOSSIERLOADING", false);
      throw error.response.data.message;
    }
  },
  async store_dossier({ commit }, payload) {
    commit("SET_DOSSIERLOADING", true);
    try {
      const response = await axios.post(domain + `/ocrdossiers`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("PUSH_DOSSIER", response.data.data);
      commit("UPDATE_DOSSIER_ROW", state.dossierRows + 1);
      commit("SET_DOSSIERLOADING", false);
      return true;
    } catch (error) {
      commit("SET_DOSSIERLOADING", false);
      throw error.response.data.message;
    }
  },
  async edit_dossier({ commit }, payload) {
    commit("SET_DOSSIERLOADING", true);
    try {
      const response = await axios.put(
        domain + `/ocrdossiers/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_DOSSIER", response.data.data);
      commit("SET_DOSSIERLOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_DOSSIERLOADING", false);
      throw error.response.data.message;
    }
  },
  async delete_dossier({ commit }, payload) {
    commit("SET_DOSSIERLOADING", true);
    try {
      await axios.delete(domain + `/ocrdossiers/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_DOSSIER", payload);
      commit("UPDATE_DOSSIER_ROW", state.dossierRows - 1);
      commit("SET_DOSSIERLOADING", false);
      return true;
    } catch (error) {
      commit("SET_DOSSIERLOADING", false);
      throw error.response.data.message;
    }
  },

  async one_dossier({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const response = await axios.get(domain + `/ocrdossiers/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      return Dossier.create(response.data.data);
    } catch (error) {
      throw error.response.data.message;
    }
  },

  async allfiles({ commit }, payload) {
    commit("SET_OCR_FILES");
    var params = {
      ocr_dossier_id: payload,
    };
    try {
      const response = await axios.get(domain + "/ocrfiles", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_OCR_FILES", response.data.data);
      return true;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async upload_file({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const response = await axios.post(domain + `/ocrfiles`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      return File.create(response.data.data);
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async delete_file({ commit }, payload) {
    commit("SET_DOSSIERLOADING", true);
    try {
      await axios.delete(domain + `/ocrfiles/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("SET_DOSSIERLOADING", false);
      return true;
    } catch (error) {
      commit("SET_DOSSIERLOADING", false);
      throw error.response.data.message;
    }
  },

  async send_dossier({ commit }, payload) {
    commit("SET_DOSSIERLOADING", true);
    try {
      await axios.post(domain + `/ocrdossiers-send/` + payload, null, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("SET_DOSSIERLOADING", false);
      return true;
    } catch (error) {
      commit("SET_DOSSIERLOADING", false);
      throw error.response.data.message;
    }
  },

  async download_file({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const response = await axios.get(
        domain + `/ocrfiles-download/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
          responseType: "blob",
        }
      );

      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
