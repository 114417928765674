import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import fr from 'vuetify/lib/locale/fr'
import en from 'vuetify/lib/locale/en'
import it from 'vuetify/lib/locale/it'
import de from 'vuetify/lib/locale/de'
import ar from 'vuetify/lib/locale/ar'
import pl from 'vuetify/lib/locale/pl'
import pt from 'vuetify/lib/locale/pt'
import ro from 'vuetify/lib/locale/ro'

export default new Vuetify({
    lang: {
      locales: { fr, en, it, de, ar, pl, pt, ro},
      current: 'fr',
    },
  })
