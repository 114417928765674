import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/main.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.token) {
        if (to.name == "Home") {
          if (store.state.user.user.role == "user.client")
            router.push({ path: "/devis/list" });
          else if (store.state.user.user.role == "user.super-admin")
            router.push({ path: "/statistique" });
          else if (store.state.user.user.role == "user.entreprise-admin")
            router.push({ path: "/statistique" });
          else if (store.state.user.user.role == "user.assistant")
            router.push({ path: "/statistique" });
          else if (store.state.user.user.role == "user.comptable")
            router.push({ path: "/facture/list" });
          else router.push({ path: "/client" });
        } else next();
      } else router.push({ path: "/login" });
    },
    meta: { sidebarClient: true },
    children: [
      {
        path: "/statistique",
        name: "statistique",
        component: () => import("../views/statistique.vue"),
        meta: { auth: true, sidebar: false },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/dossiers",
        name: "dossiers",
        component: () => import("../views/dossiers.vue"),
        meta: { auth: true, sidebarClient: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "client",
        name: "Client",
        component: () => import("../views/Clients.vue"),
        meta: { auth: true, sidebarClient: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant" ||
            store.state.user.user.role == "user.technicien" ||
            store.state.user.user.role == "user.commercial-entreprise"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: () => import("../views/notifications.vue"),
        meta: { auth: true, sidebar: true },
      },
      {
        path: "/agenda",
        name: "Agenda",
        component: () => import("../views/agenda.vue"),
        meta: { auth: true, sidebarClient: true },
      },
      {
        path: "/devis",
        name: "Devis",
        component: () => import("../views/devis.vue"),
        meta: { auth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant" ||
            store.state.user.user.role == "user.client" ||
            store.state.user.user.role == "user.commercial-entreprise"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/devis/list",
        name: "Devis List",
        component: () => import("../views/devisList.vue"),
        meta: { auth: true, sidebarClient: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant" ||
            store.state.user.user.role == "user.client" ||
            store.state.user.user.role == "user.commercial-entreprise" ||
            store.state.user.user.role == "user.technicien"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      // {
      //   path: "/devis/new",
      //   name: "Nouveau Devis",
      //   component: () => import("../views/newDevis.vue"),
      //   meta: { auth: true, sidebarClient: true },
      // },
      {
        path: "/devis/manuel",
        name: "Devis Manuel",
        component: () => import("../views/devisManuel.vue"),
        meta: { auth: true, sidebarClient: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant" ||
            store.state.user.user.role == "user.commercial-entreprise"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/facture/manuelle",
        name: "Facture Manuelle",
        component: () => import("../views/factureManuel.vue"),
        meta: { auth: true, sidebarClient: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant" ||
            store.state.user.user.role == "user.commercial-entreprise"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/facture",
        name: "Facture",
        component: () => import("../views/facture.vue"),
        meta: { auth: true, sidebarClient: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant" ||
            store.state.user.user.role == "user.comptable" ||
            store.state.user.user.role == "user.client" ||
            store.state.user.user.role == "user.commercial-entreprise"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/facture/list",
        name: "Facture List",
        component: () => import("../views/FactureList.vue"),
        meta: { auth: true, sidebarClient: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant" ||
            store.state.user.user.role == "user.comptable" ||
            store.state.user.user.role == "user.client" ||
            store.state.user.user.role == "user.commercial-entreprise"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/acompte/list",
        name: "Acompte List",
        component: () => import("../views/AcompteList.vue"),
        meta: { auth: true, sidebarClient: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant" ||
            store.state.user.user.role == "user.comptable" ||
            store.state.user.user.role == "user.client" ||
            store.state.user.user.role == "user.commercial-entreprise"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/avoir/list",
        name: "Avoir List",
        component: () => import("../views/AvoirList.vue"),
        meta: { auth: true, sidebarClient: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant" ||
            store.state.user.user.role == "user.comptable" ||
            store.state.user.user.role == "user.client" ||
            store.state.user.user.role == "user.commercial-entreprise"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/forfait",
        name: "forfait",
        component: () => import("../views/forfait.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/how-it-work",
        name: "how-it-work",
        redirect: "/how-it-work/infoPersonnelle",
        component: () => import("../views/how-it-work.vue"),
        meta: { auth: true },
        children: [
          {
            path: "/how-it-work/exemple",
            component: () =>
              import("../views/StaticPages/CommentMarche/exemple.vue"),
          },
          {
            path: "/how-it-work/infoPersonnelle",
            component: () =>
              import("../views/StaticPages/CommentMarche/infoPersonnelle.vue"),
          },
          {
            path: "/how-it-work/infoEntreprise",
            component: () =>
              import("../views/StaticPages/CommentMarche/infoEntreprise.vue"),
          },
          {
            path: "/how-it-work/authentification",
            component: () =>
              import("../views/StaticPages/CommentMarche/authentification.vue"),
          },
          {
            path: "/how-it-work/article",
            component: () =>
              import("../views/StaticPages/CommentMarche/article.vue"),
          },
          {
            path: "/how-it-work/client",
            component: () =>
              import("../views/StaticPages/CommentMarche/client.vue"),
          },
          {
            path: "/how-it-work/forfait",
            component: () =>
              import("../views/StaticPages/CommentMarche/forfait.vue"),
          },
          {
            path: "/how-it-work/mainOeuvre",
            component: () =>
              import("../views/StaticPages/CommentMarche/mainOeuvre.vue"),
          },
          {
            path: "/how-it-work/piece",
            component: () =>
              import("../views/StaticPages/CommentMarche/piece.vue"),
          },
          {
            path: "/how-it-work/travaux",
            component: () =>
              import("../views/StaticPages/CommentMarche/travaux.vue"),
          },
          {
            path: "/how-it-work/utilisateur",
            component: () =>
              import("../views/StaticPages/CommentMarche/utilisateur.vue"),
          },
          {
            path: "/how-it-work/categorie",
            component: () =>
              import("../views/StaticPages/CommentMarche/categorie.vue"),
          },
          {
            path: "/how-it-work/agenda",
            component: () =>
              import("../views/StaticPages/CommentMarche/agenda.vue"),
          },
          {
            path: "/how-it-work/devis",
            component: () =>
              import("../views/StaticPages/CommentMarche/devis.vue"),
          },
          {
            path: "/how-it-work/facture",
            component: () =>
              import("../views/StaticPages/CommentMarche/facture.vue"),
          },
          {
            path: "/how-it-work/traduction",
            component: () =>
              import("../views/StaticPages/CommentMarche/traduction.vue"),
          },
          {
            path: "/how-it-work/sousTraitant",
            component: () =>
              import("../views/StaticPages/CommentMarche/sousTraitant.vue"),
          },
          {
            path: "/how-it-work/delegataire",
            component: () =>
              import("../views/StaticPages/CommentMarche/delegataire.vue"),
          },
        ],
      },
      {
        path: "/visibilite-mainoeuvre",
        name: "visibilite-mainoeuvre",
        component: () => import("../views/mainoeuvreVisibility.vue"),
        meta: { auth: true, sidebar: true },
      },
      {
        path: "/visibilite-forfait",
        name: "visibilite-forfait",
        component: () => import("../views/forfaitVisibility.vue"),
        meta: { auth: true, sidebar: true },
      },
      {
        path: "/visibilite-article",
        name: "visibilite-article",
        component: () => import("../views/articleVisibility.vue"),
        meta: { auth: true, sidebar: true },
      },
      {
        path: "/visibilite-categories",
        name: "visibilite-categories",
        component: () => import("../views/categoriesVisibility.vue"),
        meta: { auth: true, sidebar: true },
      },
      {
        path: "/visibilite-metier",
        name: "visibilite-metier",
        component: () => import("../views/metierVisibility.vue"),
        meta: { auth: true, sidebar: true },
      },
      {
        path: "/mainoeuvre",
        name: "mainoeuvre",
        component: () => import("../views/mainoeuvre.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/fourniture",
        name: "fourniture",
        component: () => import("../views/fourniture.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/prestations",
        name: "Prestations",
        component: () => import("../views/prestations.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/delegataire",
        name: "delegataire",
        component: () => import("../views/delegataire.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/categorie-article",
        name: "categorie-article",
        component: () => import("../views/categorieArticle.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (store.state.user.user.role == "user.super-admin") next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/categorie-metier",
        name: "categorie-metier",
        component: () => import("../views/categorieMetier.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (store.state.user.user.role == "user.super-admin") next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/pieces",
        name: "Pieces",
        component: () => import("../views/pieces.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/sous-traitant",
        name: "sousTraitant",
        component: () => import("../views/sousTraitant.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/travaux",
        name: "Travaux",
        component: () => import("../views/travaux.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin" ||
            store.state.user.user.role == "user.assistant"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/Personal_information",
        name: "Personal Information",
        component: () => import("../views/personalInfo.vue"),
        meta: { auth: true, sidebar: true },
      },
      {
        path: "/Entreprise_information",
        name: "Entreprise Information",
        component: () => import("../views/entrepriseInfo.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role != "user.super-admin" ||
            store.state.user.user.role != "user.client"
          )
            next();
          else router.push({ path: "/" });
        },
      },
      {
        path: "/Entreprise_list",
        name: "Entreprise List",
        component: () => import("../views/entrepriseList.vue"),
        meta: { auth: true, sidebar: true, superAdminOnly: true },
      },
      {
        path: "/langue",
        name: "Langue",
        component: () => import("../views/langue.vue"),
        meta: { auth: true, sidebar: true, superAdminOnly: true },
      },
      {
        path: "/dictionnaire-vitrine",
        name: "dictionnaire-vitrine",
        component: () => import("../views/dicVitrine.vue"),
        meta: { auth: true, sidebar: true, superAdminOnly: true },
      },
      {
        path: "/dictionnaire",
        name: "Dictionnaire",
        component: () => import("../views/dictionnaire.vue"),
        meta: { auth: true, sidebar: true, superAdminOnly: true },
      },
      {
        path: "/gallery",
        name: "Gallery",
        component: () => import("../views/gallery.vue"),
        meta: { auth: true, sidebar: true, superAdminOnly: true },
      },
      {
        path: "/fichier",
        name: "CategorieFichier",
        component: () => import("../views/CategorieFichier.vue"),
        meta: { auth: true, sidebar: true, superAdminOnly: true },
      },
      {
        path: "/users",
        name: "Users",
        component: () => import("../views/Users.vue"),
        meta: { auth: true, sidebar: true },
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.user.role == "user.super-admin" ||
            store.state.user.user.role == "user.entreprise-admin"
          )
            next();
          else router.push({ path: "/" });
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: (to, from, next) => {
      next();
    },
    component: () => import("../views/Register.vue"),
    meta: { guest: true, auth: false, sidebar: false, sidebarClient: false },
  },
  {
    path: "/sign",
    name: "Sign",
    component: () => import("../views/StaticPages/Sign.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/StaticPages/Privacy.vue"),
  },
  {
    path: "/cgu",
    name: "cgu",
    component: () => import("../views/StaticPages/CGU.vue"),
  },
  {
    path: "/about_us",
    name: "About",
    component: () => import("../views/StaticPages/About.vue"),
  },
  {
    path: "/reset/password/:token",
    name: "Reset Password",
    component: () => import("../views/Register.vue"),
    meta: { guest: true, sidebar: false, sidebarClient: false },
  },
  {
    path: "/locked",
    name: "Locked",
    component: () => import("../views/StaticPages/locked.vue"),
    meta: { auth: true },
  },
  {
    path: "**",
    name: "Not Found",
    redirect: () => {
      return { path: "/client" };
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   }
  //   return { left: 0, top: 0 };
  // },
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); // Défilement vers le haut de la page
  if (store.state.token) {
    if (!store.state.user.user.entreprise) {
      if (to.name != "Login") next({ path: "/login" });
    }
  }
  if (to.matched.some((record) => record.meta.superAdminOnly)) {
    if (store.state.user.user.role == "user.super-admin") {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    next();
  }
});

export default router;
