export default class Dossier {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.status = data.status;
    this.client = data.client;
    this.client_id = data.client_id;
    this.delegataire = data.ocr_delegataire;
    this.delegataire_id = data.ocr_delegataire_id;
    this.entreprise_id = data.entreprise_id;
    this.description = data.description;
    this.created_at = data.created_at;
    this.lock = data.lock;
    this.is_ready = data.is_ready;
  }
  update(data) {
    this.id = data.id;
    this.name = data.name;
    this.status = data.status;
    this.client = data.client;
    this.client_id = data.client_id;
    this.delegataire = data.ocr_delegataire;
    this.delegataire_id = data.ocr_delegataire_id;
    this.entreprise_id = data.entreprise_id;
    this.description = data.description;
    this.created_at = data.created_at;
    this.lock = data.lock;
    this.is_ready = data.is_ready;
  }
  static create(data) {
    return new Dossier(data);
  }
}
