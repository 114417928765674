import Entreprise from "./entreprise";

export default class User {
  constructor(data) {
    this.id = data.id;
    this.nom = data.nom;
    this.prenom = data.prenom;
    this.full_name = data.nom + " " + data.prenom;
    this.password = data.password;
    this.password_confirmation = data.password_confirmation;
    this.email_u = data.email_u;
    this.email_verified_at = data.email_verified_at;
    this.password_changed_at = data.password_changed_at;
    this.tel_u = data.tel_u;
    this.phone = data.phone;
    this.role = data.role;
    this.userable_id = data.userable_id;
    this.userable_type = data.userable_type;
    this.created_by = data.created_by;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.entreprise) {
      this.entreprise = Entreprise.create(data.entreprise);
    }
    this.current_language = data.current_language;
    this.ville = data.ville;
    this.cp = data.cp;
    this.adresse = data.adresse;
    this.payss_u = data.payss_u;
    this.pays = data.pays;
    this.active = data.active;
    this.visibility = data.visibility;
    this.first_admin = data.first_admin;
    this.last_login = data.last_login;
  }
  update(data) {
    this.nom = data.nom;
    this.prenom = data.prenom;
    this.email_u = data.email_u;
    this.full_name = data.nom + " " + data.prenom;
    this.email_verified_at = data.email_verified_at;
    this.password_changed_at = data.password_changed_at;
    this.password = data.password;
    this.password_confirmation = data.password_confirmation;
    this.tel_u = data.tel_u;
    this.phone = data.phone;
    this.role = data.role;
    this.userable_id = data.userable_id;
    this.userable_type = data.userable_type;
    this.created_by = data.created_by;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.entreprise = data.entreprise;
    this.current_language = data.current_language;
    this.stripe_id = data.stripe_id;
    this.card_brand = data.card_brand;
    this.card_last_four = data.card_last_four;
    this.trial_ends_at = data.trial_ends_at;
    this.ville = data.ville;
    this.cp = data.cp;
    this.adresse = data.adresse;
    this.payss_u = data.payss_u;
    this.pays = data.pays;
    this.active = data.active;
    this.visibility = data.visibility;
    this.first_admin = data.first_admin;
    this.last_login = data.last_login;
  }

  static create(data) {
    return new User(data);
  }
}
