export default class Pays {
  constructor(data) {
    this.cca2 = data.alpha2Code
    this.cca3 = data.alpha3Code;
    this.translations = data.translations;
    this.name = data.name
  }

  format(lang) {
    if(lang!="en")
      return {
        name: this.translations[lang],
        iso_code: this.cca2
      }
    else{
      return {
        name: this.name,
        iso_code: this.cca2
      }
    }
      
  }

  static create(data) {
    return new Pays(data);
  }
}
