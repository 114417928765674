import domain from "@/environment";
import Langue from "../../models/langue";
import axios from "axios";

const state = {
  langue: null,
  languesall: [],
  languesactive: [],
  langueLoading: false,
  langueSelected: null,
  langueRows: null,
  langueallRows: null,
};
const getters = {
  getlangue: (state) => state.langue,
  getAllLangue: (state) => state.languesall,
  getActiveLangue: (state) => state.languesactive,
  getLoadinglangue: (state) => state.langueLoading,
  getLangueSelected: (state) => state.langueSelected,
  getTotalLangue: (state) => state.langueRows,
  getTotalLangueall: (state) => state.langueallRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGLANGUE(state, payload) {
    if (payload) {
      state.langueLoading = payload;
    } else {
      state.langueLoading = false;
    }
  },
  SET_LANGUE(state, payload) {
    if (payload) {
      state.langue = Langue.create(payload);
    } else {
      state.langue = null;
    }
  },
  PUSH_LANGUE(state, payload) {
    state.languesall.push(Langue.create(payload));
  },

  UPDATE_LANGUE(state, payload) {
    for (let index = 0; index < state.languesall.length; index++) {
      if (state.languesall[index].id == payload.id) {
        state.languesall[index].update(payload);
        break;
      }
    }
  },

  DELETE_LANGUE(state, payload) {
    for (let index = 0; index < state.languesall.length; index++) {
      if (state.languesall[index].id == payload.id) {
        state.languesall.splice(index, 1);
        break;
      }
    }
  },
  SET_LANGUES(state, payload) {
    if (payload) {
      state.languesall = payload.map((item) => Langue.create(item));
    } else {
      state.languesall = null;
    }
  },
  SET_ACTIVE_LANGUES(state, payload) {
    if (payload) {
      state.languesactive = payload.map((item) => Langue.create(item));
    } else {
      state.languesactive = null;
    }
  },

  UPDATE_LANGUE_ROW(state, payload) {
    if (payload) {
      state.langueRows = payload;
    } else {
      state.langueRows = null;
    }
  },
  UPDATEALL_LANGUE_ROW(state, payload) {
    if (payload) {
      state.langueallRows = payload;
    } else {
      state.langueallRows = null;
    }
  },
};

const actions = {
  async allLangue({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
    };
    try {
      const responce = await axios.get(domain + `/langues`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_LANGUES", responce.data.data.data);
      commit("UPDATEALL_LANGUE_ROW", responce.data.data.total);
      return responce.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async allActiveLangues({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      active: 1,
    };
    try {
      const responce = await axios.get(domain + `/langues`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_ACTIVE_LANGUES", responce.data.data.data);
      commit("UPDATE_LANGUE_ROW", responce.data.data.total);
      return responce.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async langue({ commit }, payload) {
    try {
      const responce = await axios.get(domain + `/langues/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_LANGUE", responce.data.data);
      return responce.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async store_langue({ commit }, payload) {
    commit("SET_LOADINGLANGUE", true);
    const formData = new FormData();
    formData.append("lib", payload.lib);
    formData.append("iso_code", payload.iso_code);
    try {
      const responce = await axios.post(domain + `/langues`, formData, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("PUSH_LANGUE", responce.data.data);
      commit("UPDATE_LANGUE_ROW", state.langueRows + 1);
      commit("SET_LOADINGLANGUE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGLANGUE", false);
      throw error.response.data.message;
    }
  },
  async edit_langue({ commit }, payload) {
    commit("SET_LOADINGLANGUE", true);

    try {
      const responce = await axios.post(
        domain + `/langues/update/` + payload.id,
        null,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
          params: payload,
        }
      );
      commit("UPDATE_LANGUE", responce.data.data);
      commit("SET_LOADINGLANGUE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGLANGUE", false);
      throw error.response.data.message;
    }
  },
  async delete_langue({ commit }, payload) {
    commit("SET_LOADINGLANGUE", true);
    try {
      await axios.delete(domain + `/langues/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_LANGUE", payload);
      commit("UPDATE_LANGUE_ROW", state.langueRows - 1);
      commit("SET_LOADINGLANGUE", false);
      return true;
    } catch (error) {
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
