export default class Avoir {
  constructor(data) {
    this.id = data.id;
    this.numero_sequence = data.numero_sequence;
    this.user = data.user;
    this.acompte_id = data.acompte_id;
    this.acompte = data.acompte;
    this.entreprise = data.entreprise;
    this.document = data.document;
    this.facture_id = data.facture_id;
    this.facture = data.facture;
    this.created_at = data.created_at;
  }
  update(data) {
    this.id = data.id;
    this.numero_sequence = data.numero_sequence;
    this.user = data.user;
    this.acompte_id = data.acompte_id;
    this.acompte = data.acompte;
    this.entreprise = data.entreprise;
    this.document = data.document;
    this.facture_id = data.facture_id;
    this.facture = data.facture;
    this.created_at = data.created_at;
  }
  static create(data) {
    return new Avoir(data);
  }
}
